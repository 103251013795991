import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { Context } from '@milo/types';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CountryCode: any;
  DateTime: string;
  JSON: any;
  Void: any;
};







export type ApiResponse = {
  __typename?: 'ApiResponse';
  data?: Maybe<Scalars['JSON']>;
  redirect_url?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Query = {
  __typename?: 'Query';
  ActivationCodesList?: Maybe<Array<Maybe<ActivationCode>>>;
  ActivationCodeCheck: ApiResponse;
  CategoriesList?: Maybe<Array<Maybe<Category>>>;
  CountryCurrentLocale?: Maybe<Country>;
  CountriesList: Array<Maybe<Country>>;
  ProvincesList?: Maybe<Array<Maybe<Province>>>;
  DistributorsList?: Maybe<Array<Maybe<Distributor>>>;
  DistributorById?: Maybe<Distributor>;
  DistributorBySlug?: Maybe<Distributor>;
  DistributorDiscountsActive?: Maybe<Array<Maybe<DistributorDiscount>>>;
  ExperienceGet?: Maybe<Experience>;
  ExperienceList?: Maybe<Array<Maybe<Experience>>>;
  ExperienceBatchGet?: Maybe<Array<Maybe<Experience>>>;
  FeatureFlagActive: Scalars['Boolean'];
  GroupsList?: Maybe<Array<Maybe<Group>>>;
  GuideGet?: Maybe<Guide>;
  GuidesList?: Maybe<Array<Maybe<Guide>>>;
  GuidesPopular?: Maybe<Array<Maybe<Guide>>>;
  NewsletterGetMailingLists?: Maybe<Array<Maybe<MailingList>>>;
  PartnerCurrent?: Maybe<Partner>;
  PartnerExperienceContract: PartnershipContract;
  PartnerExperienceStats?: Maybe<Partner>;
  PartnerContactEmails?: Maybe<ContactEmail>;
  PartnerStats: PartnerStats;
  PartnershipCurrentGetExperienceInfo?: Maybe<Experience>;
  PartnershipFindFromEmailOrCode: ApiResponse;
  PartnerUserCurrent?: Maybe<PartnerUser>;
  PointOfInterestList?: Maybe<Array<Maybe<PointOfInterest>>>;
  PromotionalPartners?: Maybe<Array<Maybe<PromotionalPartner>>>;
  ProductsList?: Maybe<Array<Maybe<Product>>>;
  PromoCodeCheck: ApiResponse;
  PromotionActive?: Maybe<Promotion>;
  RedeemCodeCheck: ApiResponse;
  RegionsList?: Maybe<Array<Maybe<Region>>>;
  ProductGetPriceDetails: ApiResponse;
  Subscription?: Maybe<Subscription>;
  SubscriptionGetPriceDetails: ApiResponse;
  TagList?: Maybe<Array<Maybe<Tag>>>;
  UsersList?: Maybe<Array<User>>;
  UserCurrent?: Maybe<User>;
  UserGetOnboardingData?: Maybe<UserOnboardingData>;
  UserCheckStatusByEmail: UserStatus;
  UserSearch?: Maybe<Array<Maybe<User>>>;
  UserCheckEmailAvailability: ApiResponse;
  VerticalsList?: Maybe<Array<Maybe<Vertical>>>;
  VerticalsEphemeralList?: Maybe<Array<Maybe<VerticalEphemeral>>>;
  VisitsByExperience?: Maybe<Array<Maybe<VisitHistoryRow>>>;
  PrimaryWishlist?: Maybe<Wishlist>;
  WishlistAll?: Maybe<Array<Maybe<Wishlist>>>;
};


export type QueryActivationCodeCheckArgs = {
  input?: Maybe<ActivationCodeCheckInput>;
};


export type QueryProvincesListArgs = {
  countryCode: Scalars['CountryCode'];
};


export type QueryDistributorByIdArgs = {
  id: Scalars['ID'];
};


export type QueryDistributorBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryExperienceGetArgs = {
  id: Scalars['Int'];
};


export type QueryExperienceBatchGetArgs = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryFeatureFlagActiveArgs = {
  input?: Maybe<FeatureFlagInput>;
};


export type QueryGuideGetArgs = {
  id: Scalars['Int'];
};


export type QueryPartnerExperienceContractArgs = {
  experience_id: Scalars['String'];
};


export type QueryPartnerStatsArgs = {
  experience_id: Scalars['Int'];
};


export type QueryPartnershipCurrentGetExperienceInfoArgs = {
  onboarding_code: Scalars['String'];
};


export type QueryPartnershipFindFromEmailOrCodeArgs = {
  email_or_code: Scalars['String'];
};


export type QueryPromoCodeCheckArgs = {
  code: Scalars['String'];
  productId: Scalars['String'];
};


export type QueryRedeemCodeCheckArgs = {
  code: Scalars['String'];
};


export type QueryProductGetPriceDetailsArgs = {
  input: ProductGetPriceDetailsInput;
};


export type QuerySubscriptionArgs = {
  stripe_customer_id: Scalars['ID'];
};


export type QuerySubscriptionGetPriceDetailsArgs = {
  input?: Maybe<SubscriptionPurchaseInput>;
};


export type QueryUsersListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryUserCheckStatusByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserSearchArgs = {
  string: Scalars['String'];
};


export type QueryUserCheckEmailAvailabilityArgs = {
  email: Scalars['String'];
};


export type QueryVisitsByExperienceArgs = {
  experience_id: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  cursor_id?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ActivationCodeOffer: ApiResponse;
  ActivationCodePurchase: ApiResponse;
  ActivationCodeUse: ApiResponse;
  CacheClear?: Maybe<ApiResponse>;
  ExperienceUpdateInfo: ApiResponse;
  GroupDelete?: Maybe<Group>;
  LandingContestSaveUser: ApiResponse;
  NewsletterUnsubscribeMember: ApiResponse;
  NewsletterAddVisitor: ApiResponse;
  OtpGet: OtpGetResponse;
  OtpVerify: Scalars['Boolean'];
  PartnerUserLogout: ApiResponse;
  PartnerLogin: ApiResponse;
  PartnerRemindCredentials: ApiResponse;
  PartnerRemindAdminPassword: ApiResponse;
  PartnerCreateAdminPassword: ApiResponse;
  PartnerChangeAdminPassword: ApiResponse;
  PartnerAuthAdmin: ApiResponse;
  PartnershipAcceptTerms: ApiResponse;
  PartnershipOnboardingCodeLogin: ApiResponse;
  PartnerUserLoginOrCreate: ApiResponse;
  PaymentMethodAdd: ApiResponse;
  PaymentMethodRemove: ApiResponse;
  RegisterToPromotionalPartner?: Maybe<ApiResponse>;
  UnregisterFromPromotionalPartner?: Maybe<ApiResponse>;
  RedeemCodeUse: ApiResponse;
  ProductPurchase: ApiResponse;
  SubscriptionUpdate: ApiResponse;
  SubscriptionPurchase: ApiResponse;
  UserActivateLegacySubscription: ApiResponse;
  UserDelete: ApiResponse;
  UserLogin: User;
  UserLogout: ApiResponse;
  UserOtpGet: UserOtpGetResponse;
  UserOtpLogin: ApiResponse;
  UserUpdate: ApiResponse;
  VisitConfirm: ApiResponse;
  VisitConfirmMember: ApiResponse;
  VisitUpdate: ApiResponse;
  FreeVisitCreate: ApiResponse;
  WishlistUpdateExperiences?: Maybe<ApiResponse>;
  WishlistCreate?: Maybe<ApiResponse>;
  WishlistDelete?: Maybe<ApiResponse>;
  WishlistUpdate?: Maybe<ApiResponse>;
};


export type MutationActivationCodeOfferArgs = {
  input: ActivationCodeOfferInput;
};


export type MutationActivationCodePurchaseArgs = {
  input: ActivationCodePurchaseInput;
};


export type MutationActivationCodeUseArgs = {
  input: ActivationCodeUseInput;
};


export type MutationCacheClearArgs = {
  queryName: Scalars['String'];
};


export type MutationExperienceUpdateInfoArgs = {
  input: ExperienceUpdateInfoInput;
  onboarding_code: Scalars['String'];
};


export type MutationLandingContestSaveUserArgs = {
  input: LandingContestSaveUserInput;
};


export type MutationNewsletterUnsubscribeMemberArgs = {
  input: NewsletterUnsubscribeMemberInput;
};


export type MutationNewsletterAddVisitorArgs = {
  email: Scalars['String'];
};


export type MutationOtpGetArgs = {
  input: OtpGetInput;
};


export type MutationOtpVerifyArgs = {
  input: OtpVerifyInput;
};


export type MutationPartnerLoginArgs = {
  input: PartnerLoginInput;
};


export type MutationPartnerRemindCredentialsArgs = {
  input: EmailInput;
};


export type MutationPartnerRemindAdminPasswordArgs = {
  input: EmailInput;
};


export type MutationPartnerCreateAdminPasswordArgs = {
  input: EmailInput;
};


export type MutationPartnerChangeAdminPasswordArgs = {
  input: ChangeAdminPasswordInput;
};


export type MutationPartnerAuthAdminArgs = {
  input: AdminPasswordInput;
};


export type MutationPartnershipAcceptTermsArgs = {
  onboarding_code: Scalars['String'];
  accept_terms_partner_user_id: Scalars['Int'];
  partner_type: PartnerType;
};


export type MutationPartnershipOnboardingCodeLoginArgs = {
  onboarding_code: Scalars['String'];
};


export type MutationPartnerUserLoginOrCreateArgs = {
  input: PartnerUserLoginInput;
};


export type MutationPaymentMethodAddArgs = {
  input: PaymentMethodInput;
};


export type MutationPaymentMethodRemoveArgs = {
  input: PaymentMethodInput;
};


export type MutationRegisterToPromotionalPartnerArgs = {
  promotional_partner_id: Scalars['String'];
  user_id_at_partner: Scalars['String'];
};


export type MutationUnregisterFromPromotionalPartnerArgs = {
  promotional_partner_id: Scalars['String'];
};


export type MutationRedeemCodeUseArgs = {
  code: Scalars['String'];
};


export type MutationProductPurchaseArgs = {
  input: ProductPurchaseInput;
};


export type MutationSubscriptionUpdateArgs = {
  input: SubscriptionUpdateInput;
};


export type MutationSubscriptionPurchaseArgs = {
  input: SubscriptionPurchaseInput;
};


export type MutationUserLoginArgs = {
  input: UserCredentialsInput;
};


export type MutationUserOtpGetArgs = {
  input: UserOtpGetInput;
};


export type MutationUserOtpLoginArgs = {
  input: UserOtpLoginInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationVisitConfirmArgs = {
  input: VisitConfirmInput;
};


export type MutationVisitConfirmMemberArgs = {
  input: VisitConfirmInput;
};


export type MutationVisitUpdateArgs = {
  input: VisitUpdateInput;
  userId?: Maybe<Scalars['Int']>;
};


export type MutationWishlistUpdateExperiencesArgs = {
  input: WishlistUpdateExperiencesInput;
};


export type MutationWishlistCreateArgs = {
  input: WishlistCreateInput;
};


export type MutationWishlistDeleteArgs = {
  input: WishlistDeleteInput;
};


export type MutationWishlistUpdateArgs = {
  input: WishlistUpdateInput;
};

export type ActivationCodeOfferInput = {
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  purchase_user_email?: Maybe<Scalars['String']>;
  purchase_user_first_name: Scalars['String'];
  purchase_user_last_name: Scalars['String'];
  recipient_user_email: Scalars['String'];
  recipient_user_first_name: Scalars['String'];
  recipient_user_last_name: Scalars['String'];
  send_date: Scalars['DateTime'];
};

export type ActivationCode = {
  __typename?: 'ActivationCode';
  id: Scalars['Int'];
  base_duration?: Maybe<Scalars['JSON']>;
  code: Scalars['String'];
  extra_days?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  purchase_user_email?: Maybe<Scalars['String']>;
  purchase_user_first_name?: Maybe<Scalars['String']>;
  purchase_user_id?: Maybe<Scalars['Int']>;
  purchase_user_last_name?: Maybe<Scalars['String']>;
  recipient_user_email: Scalars['String'];
  recipient_user_first_name: Scalars['String'];
  recipient_user_last_name: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  used_at?: Maybe<Scalars['DateTime']>;
  used_by_id?: Maybe<Scalars['Int']>;
  valid_at: Scalars['DateTime'];
};

export type ActivationCodeUseInput = {
  code: Scalars['String'];
};

export type ActivationCodeCheckInput = {
  code: Scalars['String'];
};

export type ActivationCodePurchaseInput = {
  country?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  purchase_user_email?: Maybe<Scalars['String']>;
  purchase_user_first_name?: Maybe<Scalars['String']>;
  purchase_user_last_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  stripe_payment_method?: Maybe<Scalars['String']>;
  purchase_mode?: Maybe<PurchaseMode>;
  product?: Maybe<Scalars['String']>;
};

export enum PurchaseMode {
  Self = 'self',
  Offer = 'offer'
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type Province = {
  __typename?: 'Province';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  id?: Maybe<Scalars['ID']>;
  balance?: Maybe<Scalars['Int']>;
};

export type DistributorDiscount = {
  __typename?: 'DistributorDiscount';
  applicable_product_id: Scalars['String'];
  discounted_amount: Scalars['Float'];
};

export type Distributor = {
  __typename?: 'Distributor';
  id: Scalars['ID'];
  description: Scalars['String'];
  extra_description?: Maybe<Scalars['String']>;
  header_text?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
  logo_url: Scalars['String'];
  name: Scalars['String'];
  offer_description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  website_url?: Maybe<Scalars['String']>;
};

export type ExperienceUpdateInfoInput = {
  primary_contact_phone: Scalars['String'];
  primary_contact_email: Scalars['String'];
  primary_contact_first_name: Scalars['String'];
  primary_contact_last_name: Scalars['String'];
};

export type SavingsAmount = {
  __typename?: 'SavingsAmount';
  savings_amount: Scalars['Int'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Province>;
  country?: Maybe<Country>;
  postal_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GeoLoc = {
  __typename?: 'GeoLoc';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum SavingsTypes {
  Grid = 'grid',
  Percentage = 'percentage',
  TwoForOne = 'two_for_one'
}

/** TODO: Type it the graphQL way */
export type CurrentContract = {
  __typename?: 'CurrentContract';
  signed_date?: Maybe<Scalars['DateTime']>;
  signed_contact?: Maybe<Contact>;
};

export type Contact = {
  __typename?: 'Contact';
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type OpeningHour = {
  __typename?: 'OpeningHour';
  day?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['String']>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};

export type Owner = {
  __typename?: 'Owner';
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
};

export type Experience = {
  __typename?: 'Experience';
  _geoloc?: Maybe<GeoLoc>;
  id: Scalars['Int'];
  additional_images?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['Int']>>>;
  company_email?: Maybe<Scalars['String']>;
  company_phone?: Maybe<Scalars['String']>;
  current_contract_version?: Maybe<Scalars['Int']>;
  current_contract?: Maybe<CurrentContract>;
  current_user_rating?: Maybe<Scalars['String']>;
  customer_acquisition_cost?: Maybe<Scalars['Int']>;
  customers_distance_close?: Maybe<Scalars['Int']>;
  customers_distance_far?: Maybe<Scalars['Int']>;
  customers_distance_medium?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['DateTime']>;
  date_published?: Maybe<Scalars['DateTime']>;
  date_updated?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discovered_via_milo?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Float']>;
  facebook?: Maybe<Scalars['String']>;
  google_maps_url?: Maybe<Scalars['String']>;
  gross_sales?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  group?: Maybe<Group>;
  images?: Maybe<Array<Maybe<Image>>>;
  in_wishlist?: Maybe<Scalars['Boolean']>;
  instagram?: Maybe<Scalars['String']>;
  is_legacy?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  main_image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  opening_hours?: Maybe<Array<Maybe<OpeningHour>>>;
  owners?: Maybe<Array<Maybe<Owner>>>;
  partnerships?: Maybe<Array<Maybe<Partnership>>>;
  province?: Maybe<Scalars['String']>;
  questions_answers?: Maybe<Array<Maybe<QuestionAnswer>>>;
  ratings?: Maybe<Ratings>;
  region?: Maybe<Region>;
  reservation_conditions?: Maybe<Scalars['String']>;
  restrictions?: Maybe<Scalars['String']>;
  promo_code_instructions?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['Int']>;
  savings_amount?: Maybe<Scalars['Int']>;
  savings_code?: Maybe<Scalars['String']>;
  savings_matrix?: Maybe<SavingsMatrix>;
  savings_max?: Maybe<Scalars['Int']>;
  savings_percentage?: Maybe<Scalars['Int']>;
  savings_type?: Maybe<SavingsTypes>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<ExperienceStatus>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  terms_accepted_by?: Maybe<PartnerUser>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  thumbnail_image?: Maybe<Scalars['String']>;
  two_for_one_max_count?: Maybe<Scalars['Int']>;
  vertical_id?: Maybe<Scalars['Int']>;
  verticals_ephemeral_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  visited_this_year?: Maybe<Scalars['Boolean']>;
  visits?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
  wishlist_revenue?: Maybe<Scalars['Int']>;
  wishlist_total?: Maybe<Scalars['Int']>;
};

export type SavingsMatrix = {
  __typename?: 'SavingsMatrix';
  amount1: Scalars['Int'];
  amount2: Scalars['Int'];
  amount3: Scalars['Int'];
  savings1: Scalars['Int'];
  savings2: Scalars['Int'];
  savings3: Scalars['Int'];
};

export enum ExperienceStatus {
  Quit = 'quit',
  Prospect = 'prospect',
  QualifiedProspect = 'qualified_prospect',
  InCreation = 'in_creation',
  Online = 'online',
  DeclinedProspect = 'declined_prospect',
  Declined = 'declined'
}

export type Ratings = {
  __typename?: 'Ratings';
  bad?: Maybe<Scalars['Float']>;
  good?: Maybe<Scalars['Float']>;
  great?: Maybe<Scalars['Float']>;
};

export type FeatureFlagConditions = {
  employee?: Maybe<Scalars['Boolean']>;
};

export type FeatureFlagInput = {
  id: Scalars['ID'];
  conditions?: Maybe<FeatureFlagConditions>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['Int'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  admin_contacts?: Maybe<Scalars['JSON']>;
};

export type Guide = {
  __typename?: 'Guide';
  id: Scalars['Int'];
  city_id?: Maybe<Scalars['Int']>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  long_description: Scalars['String'];
  main_image_url: Scalars['String'];
  meta_description: Scalars['String'];
  region?: Maybe<Region>;
  related_guides?: Maybe<Array<Maybe<Guide>>>;
  savings: Scalars['Int'];
  slug: Scalars['String'];
  tags?: Maybe<Array<Maybe<Tag>>>;
  title: Scalars['String'];
  vertical?: Maybe<Vertical>;
};

export enum ParentEntityType {
  Experience = 'experience',
  Group = 'group',
  User = 'user'
}

export type Image = {
  __typename?: 'Image';
  id: Scalars['Int'];
  filename: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  parent_entity_type: ParentEntityType;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  name: Scalars['String'];
  amount: Scalars['Int'];
  offered_as_gift: Scalars['Boolean'];
  date_created: Scalars['DateTime'];
};

export type LandingContestSaveUserInput = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  contest_name: Scalars['String'];
};

export enum MailingListIds {
  All = 'all',
  Discoveries = 'discoveries',
  Escapades = 'escapades',
  Promotions = 'promotions'
}

export type MailingList = {
  __typename?: 'MailingList';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type NewsletterUnsubscribeMemberInput = {
  email: Scalars['String'];
  listIds: Array<Maybe<MailingListIds>>;
};

export type OtpGetResponse = {
  __typename?: 'OtpGetResponse';
  secret?: Maybe<Scalars['String']>;
};

export type OtpGetInput = {
  email: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
};

export type OtpVerifyInput = {
  email: Scalars['String'];
  code: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
};

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['Int'];
  experiences?: Maybe<Array<Maybe<Experience>>>;
  is_admin: Scalars['Boolean'];
  email: Scalars['String'];
  cell_phone?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_login_at: Scalars['DateTime'];
  group?: Maybe<Group>;
  has_admin_password: Scalars['Boolean'];
};

export type PartnerStatsConversions = {
  __typename?: 'PartnerStatsConversions';
  revenue: Scalars['Int'];
  partnerValidatedCount: Scalars['Int'];
  memberValidatedCount: Scalars['Int'];
};

export type PartnerStatsGenderDistribution = {
  __typename?: 'PartnerStatsGenderDistribution';
  m: Scalars['Int'];
  f: Scalars['Int'];
  o: Scalars['Int'];
};

export type PartnerStatsAgeDistribution = {
  __typename?: 'PartnerStatsAgeDistribution';
  _18_25: Scalars['Int'];
  _26_35: Scalars['Int'];
  _36_49: Scalars['Int'];
  _50_plus: Scalars['Int'];
};

export type PartnerStatsRegionDistribution = {
  __typename?: 'PartnerStatsRegionDistribution';
  local: Scalars['Int'];
  other: Scalars['Int'];
};

export type PartnerMonthlyVisits = {
  __typename?: 'PartnerMonthlyVisits';
  count: Scalars['Int'];
  month: Scalars['String'];
};

export type PartnerStats = {
  __typename?: 'PartnerStats';
  age_distribution: PartnerStatsAgeDistribution;
  conversions: PartnerStatsConversions;
  gender_distribution: PartnerStatsGenderDistribution;
  monthly_visits: Array<Maybe<PartnerMonthlyVisits>>;
  region_distribution: PartnerStatsRegionDistribution;
};

export type ContactEmail = {
  __typename?: 'ContactEmail';
  emails: Array<Scalars['String']>;
};

export type EmailInput = {
  email: Scalars['String'];
};

export type PartnerLoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type ChangeAdminPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  confirm_password: Scalars['String'];
};

export type AdminPasswordInput = {
  password: Scalars['String'];
};

export enum PartnerType {
  Prospect = 'prospect',
  Legacy = 'legacy'
}

export type Partnership = {
  __typename?: 'Partnership';
  id: Scalars['Int'];
  is_current: Scalars['Boolean'];
  auto_renew: Scalars['Boolean'];
  experience_id: Scalars['Int'];
  partner_user_accepted?: Maybe<PartnerUser>;
  accept_terms_date?: Maybe<Scalars['DateTime']>;
  passed_onboarding_date?: Maybe<Scalars['DateTime']>;
  onboarding_code?: Maybe<Scalars['String']>;
  contract?: Maybe<PartnershipContract>;
};

export type PartnershipContract = {
  __typename?: 'PartnershipContract';
  id?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['ID']>;
  user_updated?: Maybe<Scalars['ID']>;
  date_created?: Maybe<Scalars['DateTime']>;
  date_updated?: Maybe<Scalars['DateTime']>;
  version: Scalars['Int'];
  version_date?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type PartnerUser = {
  __typename?: 'PartnerUser';
  id: Scalars['Int'];
  experiences?: Maybe<Array<Maybe<Experience>>>;
  is_admin: Scalars['Boolean'];
  email: Scalars['String'];
  cell_phone?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_login_at: Scalars['DateTime'];
  group?: Maybe<Group>;
};

export type PartnerUserLoginInput = {
  email: Scalars['String'];
  onboarding_code?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  brand: Scalars['String'];
  last4: Scalars['String'];
  expiry_year: Scalars['Int'];
};

export type PaymentMethodInput = {
  payment_method_id: Scalars['ID'];
};

export type PointOfInterest = {
  __typename?: 'PointOfInterest';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  partner?: Maybe<PointOfInterestPartner>;
  primary_tag: Tag;
  secondary_tags: Array<Maybe<Tag>>;
  link?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  type: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type PointOfInterestPartner = {
  __typename?: 'PointOfInterestPartner';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  logo_url: Scalars['String'];
  website_url?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type PromotionalPartner = {
  __typename?: 'PromotionalPartner';
  id: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum ProductType {
  Recurring = 'recurring',
  Prepaid = 'prepaid'
}

export enum ProductInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

/** 2024-08-20: This is now deprecated */
export enum ProductId {
  /** Products that are not being used anymore */
  TwoMonthNonRecurring = 'two_month_non_recurring',
  ThreeMonthNonRecurring = 'three_month_non_recurring',
  SixMonthNonRecurring = 'six_month_non_recurring',
  TwelveMonthNonRecurring = 'twelve_month_non_recurring',
  MiloPassOneMonth = 'milo_pass_one_month',
  MiloPassOneWeek = 'milo_pass_one_week',
  /** Products that are being used */
  OneMonthRecurring = 'one_month_recurring',
  MiloPassOneYear = 'milo_pass_one_year'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  image_url: Scalars['String'];
  price_cents_cad: Scalars['Int'];
  base_price?: Maybe<Scalars['Int']>;
  interval_type: ProductInterval;
  interval_count: Scalars['Int'];
  is_recurring: Scalars['Boolean'];
  special_price_text?: Maybe<Scalars['String']>;
  displayed: Scalars['Boolean'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['Int'];
  code: Scalars['String'];
  applicable_product_id: ProductId;
  amount_off?: Maybe<Scalars['Int']>;
  percent_off?: Maybe<Scalars['Int']>;
  usage_count: Scalars['Int'];
  max_usage_count: Scalars['Int'];
  extra_days?: Maybe<Scalars['JSON']>;
  data?: Maybe<Scalars['JSON']>;
  valid_at?: Maybe<Scalars['DateTime']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
};

export type Promotion = {
  __typename?: 'Promotion';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discounts?: Maybe<Array<Maybe<PromotionDiscount>>>;
  starts_at?: Maybe<Scalars['DateTime']>;
  ends_at?: Maybe<Scalars['DateTime']>;
};

export type PromotionDiscount = {
  __typename?: 'PromotionDiscount';
  promo_code: Scalars['String'];
  applicable_product_id: ProductId;
  discounted_amount: Scalars['Float'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['Int'];
  slug: Scalars['String'];
  name: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
};





export type ProductPurchaseInput = {
  payment_method_id?: Maybe<Scalars['String']>;
  product_id: Scalars['String'];
  promo_code?: Maybe<Scalars['String']>;
  purchase_email?: Maybe<Scalars['String']>;
  purchase_mode: PurchaseMode;
  activation_date: Scalars['DateTime'];
  quantity?: Maybe<Scalars['Int']>;
};

export type ProductGetPriceDetailsInput = {
  product_id: Scalars['String'];
  promo_code?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export enum SubscriptionPlan {
  Monthly = 'monthly'
}

export type SubscriptionPurchaseInput = {
  subscription_plan?: Maybe<SubscriptionPlan>;
  country?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  stripe_payment_method?: Maybe<Scalars['String']>;
};

export type SubscriptionUpdateInput = {
  subscription_id: Scalars['ID'];
  auto_renew?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<SubscriptionType>;
  product_type?: Maybe<ProductType>;
  stripe_product_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  status?: Maybe<SubscriptionStatus>;
  auto_renew?: Maybe<Scalars['Boolean']>;
  started_at?: Maybe<Scalars['DateTime']>;
  pending_activation_date?: Maybe<Scalars['DateTime']>;
  cancel_date?: Maybe<Scalars['DateTime']>;
  current_period_start?: Maybe<Scalars['DateTime']>;
  current_period_end?: Maybe<Scalars['DateTime']>;
  price_cents: Scalars['Int'];
  interval_count: Scalars['Int'];
  interval: ProductInterval;
};

export enum SubscriptionType {
  Member = 'member'
}

export enum SubscriptionStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  date_created?: Maybe<Scalars['DateTime']>;
  date_updated?: Maybe<Scalars['DateTime']>;
};

export enum PromotionalPartnerStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export enum PromotionalPartnerIds {
  Caa = 'caa'
}

export type PromotionalPartnerMembershipInfo = {
  __typename?: 'PromotionalPartnerMembershipInfo';
  partner_id: PromotionalPartnerIds;
  membership_number: Scalars['String'];
  membership_type?: Maybe<Scalars['String']>;
  membership_status: PromotionalPartnerStatus;
};

export type SubscriptionSchedule = {
  __typename?: 'SubscriptionSchedule';
  priceId: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  promotional_partners_memberships: Array<Maybe<PromotionalPartnerMembershipInfo>>;
  address?: Maybe<Address>;
  birth_date?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Customer>;
  data?: Maybe<Scalars['JSON']>;
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  email: Scalars['String'];
  facebook_id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  free_visits: Array<Maybe<FreeVisit>>;
  gender?: Maybe<Gender>;
  google_id?: Maybe<Scalars['ID']>;
  has_passed_onboarding?: Maybe<Scalars['Boolean']>;
  has_pending_free_visit?: Maybe<Scalars['Boolean']>;
  has_pending_prepaid: Scalars['Boolean'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  is_legacy: Scalars['Boolean'];
  last_login_at?: Maybe<Scalars['DateTime']>;
  last_name?: Maybe<Scalars['String']>;
  last_used_free_visit?: Maybe<FreeVisit>;
  member_code?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  payment_method?: Maybe<PaymentMethod>;
  pending_free_visit?: Maybe<FreeVisit>;
  pending_promo_codes?: Maybe<Array<Maybe<PromoCode>>>;
  privilege_interval?: Maybe<PrivilegeInterval>;
  privilege_reset_day?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  stripe_customer_id?: Maybe<Scalars['ID']>;
  stripe_subscription_id?: Maybe<Scalars['ID']>;
  subscription?: Maybe<Subscription>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  scheduled_subscriptions?: Maybe<Array<Maybe<SubscriptionSchedule>>>;
  visited_this_year?: Maybe<Array<Maybe<Scalars['Int']>>>;
  visits_this_year?: Maybe<Array<Maybe<Visit>>>;
  visits?: Maybe<Array<Maybe<Visit>>>;
  wishlists?: Maybe<Array<Maybe<Wishlist>>>;
};

export enum UserStatus {
  NonUser = 'non_user',
  User = 'user',
  Member = 'member'
}

export type UserOnboardingData = {
  __typename?: 'UserOnboardingData';
  cents_credits?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  free_days?: Maybe<Scalars['Int']>;
  has_passed_onboarding: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  payment_method?: Maybe<PaymentMethod>;
  reactivated_at?: Maybe<Scalars['DateTime']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  user_source?: Maybe<Scalars['String']>;
};

export type PrivilegeInterval = {
  __typename?: 'PrivilegeInterval';
  start: Scalars['String'];
  end: Scalars['String'];
};

export type UserOtpGetResponse = {
  __typename?: 'UserOtpGetResponse';
  secret?: Maybe<Scalars['String']>;
};

export type UserOtp = {
  __typename?: 'UserOtp';
  id: Scalars['Int'];
  user_id: Scalars['Int'];
  code: Scalars['String'];
  attempts: Scalars['Int'];
  used_at?: Maybe<Scalars['DateTime']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
};

export type UserCredentialsInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum Gender {
  F = 'f',
  M = 'm',
  O = 'o'
}

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
};

export type UserUpdateInput = {
  address?: Maybe<AddressInput>;
  birth_date?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  has_passed_onboarding?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  onboarding_type?: Maybe<Scalars['String']>;
};

export type UserOtpGetInput = {
  email: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
};

export type UserOtpLoginInput = {
  email: Scalars['String'];
  code: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
};

export type Vertical = {
  __typename?: 'Vertical';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type VerticalEphemeral = {
  __typename?: 'VerticalEphemeral';
  id: Scalars['Int'];
  hex_color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  svg?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['DateTime']>;
  ends_at?: Maybe<Scalars['DateTime']>;
};

export enum VisitConfirmType {
  Partner = 'partner',
  Member = 'member'
}

export type VisitConfirmInput = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  experience_id?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  savings?: Maybe<Scalars['Int']>;
  two_for_one_purchased?: Maybe<Scalars['Int']>;
  two_for_one_received?: Maybe<Scalars['Int']>;
  invoice_total_before_tax?: Maybe<Scalars['Int']>;
  discovered_with_milo?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Platform>;
  validated_by?: Maybe<VisitConfirmType>;
};

export enum Platform {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export type VisitUpdateInput = {
  id: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  savings?: Maybe<Scalars['Int']>;
  reduced_invoice_amount?: Maybe<Scalars['Int']>;
  discovered_with_milo?: Maybe<Scalars['Boolean']>;
};

export type Visit = {
  __typename?: 'Visit';
  id: Scalars['Int'];
  user_id: Scalars['Int'];
  savings?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  experience_id: Scalars['Int'];
  experience?: Maybe<Experience>;
  reduced_invoice_amount?: Maybe<Scalars['Int']>;
  discovered_with_milo?: Maybe<Scalars['Boolean']>;
  type?: Maybe<VisitConfirmType>;
  promo_code?: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
};

export type VisitHistoryRow = {
  __typename?: 'VisitHistoryRow';
  id: Scalars['Int'];
  date_created?: Maybe<Scalars['DateTime']>;
  validated_by?: Maybe<Scalars['String']>;
  invoice_total_before_tax?: Maybe<Scalars['Int']>;
  age?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type FreeVisit = {
  __typename?: 'FreeVisit';
  id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
  visit_date?: Maybe<Scalars['DateTime']>;
  valid_until_date: Scalars['DateTime'];
  created_date: Scalars['DateTime'];
  updated_date: Scalars['DateTime'];
};

export type Wishlist = {
  __typename?: 'Wishlist';
  id: Scalars['Int'];
  user_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
};

export type WishlistUpdateExperiencesInput = {
  toAdd?: Maybe<Array<Scalars['Int']>>;
  toRemove?: Maybe<Array<Scalars['Int']>>;
  experienceId: Scalars['Int'];
};

export type WishlistCreateInput = {
  name: Scalars['String'];
  experiencesIds?: Maybe<Array<Scalars['Int']>>;
};

export type WishlistDeleteInput = {
  wishlistId: Scalars['Int'];
};

export type WishlistUpdateInput = {
  wishlistId: Scalars['Int'];
  name: Scalars['String'];
};

export type WishlistItem = {
  __typename?: 'WishlistItem';
  id: Scalars['Int'];
  experience_id: Scalars['Int'];
  wishlist_id: Scalars['Int'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ApiResponse: ResolverTypeWrapper<ApiResponse>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Query: ResolverTypeWrapper<{}>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Mutation: ResolverTypeWrapper<{}>;
  ActivationCodeOfferInput: ActivationCodeOfferInput;
  ActivationCode: ResolverTypeWrapper<ActivationCode>;
  ActivationCodeUseInput: ActivationCodeUseInput;
  ActivationCodeCheckInput: ActivationCodeCheckInput;
  ActivationCodePurchaseInput: ActivationCodePurchaseInput;
  PurchaseMode: PurchaseMode;
  Category: ResolverTypeWrapper<Category>;
  Country: ResolverTypeWrapper<Country>;
  Province: ResolverTypeWrapper<Province>;
  Customer: ResolverTypeWrapper<Customer>;
  DistributorDiscount: ResolverTypeWrapper<DistributorDiscount>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Distributor: ResolverTypeWrapper<Distributor>;
  ExperienceUpdateInfoInput: ExperienceUpdateInfoInput;
  SavingsAmount: ResolverTypeWrapper<SavingsAmount>;
  Location: ResolverTypeWrapper<Location>;
  GeoLoc: ResolverTypeWrapper<GeoLoc>;
  SavingsTypes: SavingsTypes;
  CurrentContract: ResolverTypeWrapper<CurrentContract>;
  Contact: ResolverTypeWrapper<Contact>;
  OpeningHour: ResolverTypeWrapper<OpeningHour>;
  QuestionAnswer: ResolverTypeWrapper<QuestionAnswer>;
  Owner: ResolverTypeWrapper<Owner>;
  Experience: ResolverTypeWrapper<Experience>;
  SavingsMatrix: ResolverTypeWrapper<SavingsMatrix>;
  ExperienceStatus: ExperienceStatus;
  Ratings: ResolverTypeWrapper<Ratings>;
  FeatureFlagConditions: FeatureFlagConditions;
  FeatureFlagInput: FeatureFlagInput;
  Group: ResolverTypeWrapper<Group>;
  Guide: ResolverTypeWrapper<Guide>;
  ParentEntityType: ParentEntityType;
  Image: ResolverTypeWrapper<Image>;
  Invoice: ResolverTypeWrapper<Invoice>;
  LandingContestSaveUserInput: LandingContestSaveUserInput;
  MailingListIds: MailingListIds;
  MailingList: ResolverTypeWrapper<MailingList>;
  NewsletterUnsubscribeMemberInput: NewsletterUnsubscribeMemberInput;
  OtpGetResponse: ResolverTypeWrapper<OtpGetResponse>;
  OtpGetInput: OtpGetInput;
  OtpVerifyInput: OtpVerifyInput;
  Partner: ResolverTypeWrapper<Partner>;
  PartnerStatsConversions: ResolverTypeWrapper<PartnerStatsConversions>;
  PartnerStatsGenderDistribution: ResolverTypeWrapper<PartnerStatsGenderDistribution>;
  PartnerStatsAgeDistribution: ResolverTypeWrapper<PartnerStatsAgeDistribution>;
  PartnerStatsRegionDistribution: ResolverTypeWrapper<PartnerStatsRegionDistribution>;
  PartnerMonthlyVisits: ResolverTypeWrapper<PartnerMonthlyVisits>;
  PartnerStats: ResolverTypeWrapper<PartnerStats>;
  ContactEmail: ResolverTypeWrapper<ContactEmail>;
  EmailInput: EmailInput;
  PartnerLoginInput: PartnerLoginInput;
  ChangeAdminPasswordInput: ChangeAdminPasswordInput;
  AdminPasswordInput: AdminPasswordInput;
  PartnerType: PartnerType;
  Partnership: ResolverTypeWrapper<Partnership>;
  PartnershipContract: ResolverTypeWrapper<PartnershipContract>;
  PartnerUser: ResolverTypeWrapper<PartnerUser>;
  PartnerUserLoginInput: PartnerUserLoginInput;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  PaymentMethodInput: PaymentMethodInput;
  PointOfInterest: ResolverTypeWrapper<PointOfInterest>;
  PointOfInterestPartner: ResolverTypeWrapper<PointOfInterestPartner>;
  PromotionalPartner: ResolverTypeWrapper<PromotionalPartner>;
  ProductType: ProductType;
  ProductInterval: ProductInterval;
  ProductId: ProductId;
  Product: ResolverTypeWrapper<Product>;
  PromoCode: ResolverTypeWrapper<PromoCode>;
  Promotion: ResolverTypeWrapper<Promotion>;
  PromotionDiscount: ResolverTypeWrapper<PromotionDiscount>;
  Region: ResolverTypeWrapper<Region>;
  CountryCode: ResolverTypeWrapper<Scalars['CountryCode']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  Void: ResolverTypeWrapper<Scalars['Void']>;
  ProductPurchaseInput: ProductPurchaseInput;
  ProductGetPriceDetailsInput: ProductGetPriceDetailsInput;
  SubscriptionPlan: SubscriptionPlan;
  SubscriptionPurchaseInput: SubscriptionPurchaseInput;
  SubscriptionUpdateInput: SubscriptionUpdateInput;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionType: SubscriptionType;
  SubscriptionStatus: SubscriptionStatus;
  Tag: ResolverTypeWrapper<Tag>;
  PromotionalPartnerStatus: PromotionalPartnerStatus;
  PromotionalPartnerIds: PromotionalPartnerIds;
  PromotionalPartnerMembershipInfo: ResolverTypeWrapper<PromotionalPartnerMembershipInfo>;
  SubscriptionSchedule: ResolverTypeWrapper<SubscriptionSchedule>;
  User: ResolverTypeWrapper<User>;
  UserStatus: UserStatus;
  UserOnboardingData: ResolverTypeWrapper<UserOnboardingData>;
  PrivilegeInterval: ResolverTypeWrapper<PrivilegeInterval>;
  UserOtpGetResponse: ResolverTypeWrapper<UserOtpGetResponse>;
  UserOtp: ResolverTypeWrapper<UserOtp>;
  UserCredentialsInput: UserCredentialsInput;
  Gender: Gender;
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  UserUpdateInput: UserUpdateInput;
  UserOtpGetInput: UserOtpGetInput;
  UserOtpLoginInput: UserOtpLoginInput;
  Vertical: ResolverTypeWrapper<Vertical>;
  VerticalEphemeral: ResolverTypeWrapper<VerticalEphemeral>;
  VisitConfirmType: VisitConfirmType;
  VisitConfirmInput: VisitConfirmInput;
  Platform: Platform;
  VisitUpdateInput: VisitUpdateInput;
  Visit: ResolverTypeWrapper<Visit>;
  VisitHistoryRow: ResolverTypeWrapper<VisitHistoryRow>;
  FreeVisit: ResolverTypeWrapper<FreeVisit>;
  Wishlist: ResolverTypeWrapper<Wishlist>;
  WishlistUpdateExperiencesInput: WishlistUpdateExperiencesInput;
  WishlistCreateInput: WishlistCreateInput;
  WishlistDeleteInput: WishlistDeleteInput;
  WishlistUpdateInput: WishlistUpdateInput;
  WishlistItem: ResolverTypeWrapper<WishlistItem>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ApiResponse: ApiResponse;
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  Query: {};
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Mutation: {};
  ActivationCodeOfferInput: ActivationCodeOfferInput;
  ActivationCode: ActivationCode;
  ActivationCodeUseInput: ActivationCodeUseInput;
  ActivationCodeCheckInput: ActivationCodeCheckInput;
  ActivationCodePurchaseInput: ActivationCodePurchaseInput;
  Category: Category;
  Country: Country;
  Province: Province;
  Customer: Customer;
  DistributorDiscount: DistributorDiscount;
  Float: Scalars['Float'];
  Distributor: Distributor;
  ExperienceUpdateInfoInput: ExperienceUpdateInfoInput;
  SavingsAmount: SavingsAmount;
  Location: Location;
  GeoLoc: GeoLoc;
  CurrentContract: CurrentContract;
  Contact: Contact;
  OpeningHour: OpeningHour;
  QuestionAnswer: QuestionAnswer;
  Owner: Owner;
  Experience: Experience;
  SavingsMatrix: SavingsMatrix;
  Ratings: Ratings;
  FeatureFlagConditions: FeatureFlagConditions;
  FeatureFlagInput: FeatureFlagInput;
  Group: Group;
  Guide: Guide;
  Image: Image;
  Invoice: Invoice;
  LandingContestSaveUserInput: LandingContestSaveUserInput;
  MailingList: MailingList;
  NewsletterUnsubscribeMemberInput: NewsletterUnsubscribeMemberInput;
  OtpGetResponse: OtpGetResponse;
  OtpGetInput: OtpGetInput;
  OtpVerifyInput: OtpVerifyInput;
  Partner: Partner;
  PartnerStatsConversions: PartnerStatsConversions;
  PartnerStatsGenderDistribution: PartnerStatsGenderDistribution;
  PartnerStatsAgeDistribution: PartnerStatsAgeDistribution;
  PartnerStatsRegionDistribution: PartnerStatsRegionDistribution;
  PartnerMonthlyVisits: PartnerMonthlyVisits;
  PartnerStats: PartnerStats;
  ContactEmail: ContactEmail;
  EmailInput: EmailInput;
  PartnerLoginInput: PartnerLoginInput;
  ChangeAdminPasswordInput: ChangeAdminPasswordInput;
  AdminPasswordInput: AdminPasswordInput;
  Partnership: Partnership;
  PartnershipContract: PartnershipContract;
  PartnerUser: PartnerUser;
  PartnerUserLoginInput: PartnerUserLoginInput;
  PaymentMethod: PaymentMethod;
  PaymentMethodInput: PaymentMethodInput;
  PointOfInterest: PointOfInterest;
  PointOfInterestPartner: PointOfInterestPartner;
  PromotionalPartner: PromotionalPartner;
  Product: Product;
  PromoCode: PromoCode;
  Promotion: Promotion;
  PromotionDiscount: PromotionDiscount;
  Region: Region;
  CountryCode: Scalars['CountryCode'];
  DateTime: Scalars['DateTime'];
  JSON: Scalars['JSON'];
  Void: Scalars['Void'];
  ProductPurchaseInput: ProductPurchaseInput;
  ProductGetPriceDetailsInput: ProductGetPriceDetailsInput;
  SubscriptionPurchaseInput: SubscriptionPurchaseInput;
  SubscriptionUpdateInput: SubscriptionUpdateInput;
  Subscription: {};
  Tag: Tag;
  PromotionalPartnerMembershipInfo: PromotionalPartnerMembershipInfo;
  SubscriptionSchedule: SubscriptionSchedule;
  User: User;
  UserOnboardingData: UserOnboardingData;
  PrivilegeInterval: PrivilegeInterval;
  UserOtpGetResponse: UserOtpGetResponse;
  UserOtp: UserOtp;
  UserCredentialsInput: UserCredentialsInput;
  Address: Address;
  AddressInput: AddressInput;
  UserUpdateInput: UserUpdateInput;
  UserOtpGetInput: UserOtpGetInput;
  UserOtpLoginInput: UserOtpLoginInput;
  Vertical: Vertical;
  VerticalEphemeral: VerticalEphemeral;
  VisitConfirmInput: VisitConfirmInput;
  VisitUpdateInput: VisitUpdateInput;
  Visit: Visit;
  VisitHistoryRow: VisitHistoryRow;
  FreeVisit: FreeVisit;
  Wishlist: Wishlist;
  WishlistUpdateExperiencesInput: WishlistUpdateExperiencesInput;
  WishlistCreateInput: WishlistCreateInput;
  WishlistDeleteInput: WishlistDeleteInput;
  WishlistUpdateInput: WishlistUpdateInput;
  WishlistItem: WishlistItem;
};

export type AdminrequestDirectiveArgs = {  };

export type AdminrequestDirectiveResolver<Result, Parent, ContextType = Context, Args = AdminrequestDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AuthDirectiveArgs = {  };

export type AuthDirectiveResolver<Result, Parent, ContextType = Context, Args = AuthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CacheDirectiveArgs = {  };

export type CacheDirectiveResolver<Result, Parent, ContextType = Context, Args = CacheDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type PartnerauthDirectiveArgs = {  };

export type PartnerauthDirectiveResolver<Result, Parent, ContextType = Context, Args = PartnerauthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AnyauthDirectiveArgs = {  };

export type AnyauthDirectiveResolver<Result, Parent, ContextType = Context, Args = AnyauthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type PartneradminDirectiveArgs = {  };

export type PartneradminDirectiveResolver<Result, Parent, ContextType = Context, Args = PartneradminDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ApiResponseResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ApiResponse'] = ResolversParentTypes['ApiResponse']> = {
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  redirect_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  ActivationCodesList?: Resolver<Maybe<Array<Maybe<ResolversTypes['ActivationCode']>>>, ParentType, ContextType>;
  ActivationCodeCheck?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<QueryActivationCodeCheckArgs, never>>;
  CategoriesList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  CountryCurrentLocale?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  CountriesList?: Resolver<Array<Maybe<ResolversTypes['Country']>>, ParentType, ContextType>;
  ProvincesList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Province']>>>, ParentType, ContextType, RequireFields<QueryProvincesListArgs, 'countryCode'>>;
  DistributorsList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Distributor']>>>, ParentType, ContextType>;
  DistributorById?: Resolver<Maybe<ResolversTypes['Distributor']>, ParentType, ContextType, RequireFields<QueryDistributorByIdArgs, 'id'>>;
  DistributorBySlug?: Resolver<Maybe<ResolversTypes['Distributor']>, ParentType, ContextType, RequireFields<QueryDistributorBySlugArgs, 'slug'>>;
  DistributorDiscountsActive?: Resolver<Maybe<Array<Maybe<ResolversTypes['DistributorDiscount']>>>, ParentType, ContextType>;
  ExperienceGet?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType, RequireFields<QueryExperienceGetArgs, 'id'>>;
  ExperienceList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Experience']>>>, ParentType, ContextType>;
  ExperienceBatchGet?: Resolver<Maybe<Array<Maybe<ResolversTypes['Experience']>>>, ParentType, ContextType, RequireFields<QueryExperienceBatchGetArgs, never>>;
  FeatureFlagActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryFeatureFlagActiveArgs, never>>;
  GroupsList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Group']>>>, ParentType, ContextType>;
  GuideGet?: Resolver<Maybe<ResolversTypes['Guide']>, ParentType, ContextType, RequireFields<QueryGuideGetArgs, 'id'>>;
  GuidesList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Guide']>>>, ParentType, ContextType>;
  GuidesPopular?: Resolver<Maybe<Array<Maybe<ResolversTypes['Guide']>>>, ParentType, ContextType>;
  NewsletterGetMailingLists?: Resolver<Maybe<Array<Maybe<ResolversTypes['MailingList']>>>, ParentType, ContextType>;
  PartnerCurrent?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
  PartnerExperienceContract?: Resolver<ResolversTypes['PartnershipContract'], ParentType, ContextType, RequireFields<QueryPartnerExperienceContractArgs, 'experience_id'>>;
  PartnerExperienceStats?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
  PartnerContactEmails?: Resolver<Maybe<ResolversTypes['ContactEmail']>, ParentType, ContextType>;
  PartnerStats?: Resolver<ResolversTypes['PartnerStats'], ParentType, ContextType, RequireFields<QueryPartnerStatsArgs, 'experience_id'>>;
  PartnershipCurrentGetExperienceInfo?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType, RequireFields<QueryPartnershipCurrentGetExperienceInfoArgs, 'onboarding_code'>>;
  PartnershipFindFromEmailOrCode?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<QueryPartnershipFindFromEmailOrCodeArgs, 'email_or_code'>>;
  PartnerUserCurrent?: Resolver<Maybe<ResolversTypes['PartnerUser']>, ParentType, ContextType>;
  PointOfInterestList?: Resolver<Maybe<Array<Maybe<ResolversTypes['PointOfInterest']>>>, ParentType, ContextType>;
  PromotionalPartners?: Resolver<Maybe<Array<Maybe<ResolversTypes['PromotionalPartner']>>>, ParentType, ContextType>;
  ProductsList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  PromoCodeCheck?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<QueryPromoCodeCheckArgs, 'code' | 'productId'>>;
  PromotionActive?: Resolver<Maybe<ResolversTypes['Promotion']>, ParentType, ContextType>;
  RedeemCodeCheck?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<QueryRedeemCodeCheckArgs, 'code'>>;
  RegionsList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Region']>>>, ParentType, ContextType>;
  ProductGetPriceDetails?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<QueryProductGetPriceDetailsArgs, 'input'>>;
  Subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType, RequireFields<QuerySubscriptionArgs, 'stripe_customer_id'>>;
  SubscriptionGetPriceDetails?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<QuerySubscriptionGetPriceDetailsArgs, never>>;
  TagList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tag']>>>, ParentType, ContextType>;
  UsersList?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryUsersListArgs, never>>;
  UserCurrent?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  UserGetOnboardingData?: Resolver<Maybe<ResolversTypes['UserOnboardingData']>, ParentType, ContextType>;
  UserCheckStatusByEmail?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType, RequireFields<QueryUserCheckStatusByEmailArgs, 'email'>>;
  UserSearch?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryUserSearchArgs, 'string'>>;
  UserCheckEmailAvailability?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<QueryUserCheckEmailAvailabilityArgs, 'email'>>;
  VerticalsList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vertical']>>>, ParentType, ContextType>;
  VerticalsEphemeralList?: Resolver<Maybe<Array<Maybe<ResolversTypes['VerticalEphemeral']>>>, ParentType, ContextType>;
  VisitsByExperience?: Resolver<Maybe<Array<Maybe<ResolversTypes['VisitHistoryRow']>>>, ParentType, ContextType, RequireFields<QueryVisitsByExperienceArgs, 'experience_id'>>;
  PrimaryWishlist?: Resolver<Maybe<ResolversTypes['Wishlist']>, ParentType, ContextType>;
  WishlistAll?: Resolver<Maybe<Array<Maybe<ResolversTypes['Wishlist']>>>, ParentType, ContextType>;
};

export type MutationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  ActivationCodeOffer?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationActivationCodeOfferArgs, 'input'>>;
  ActivationCodePurchase?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationActivationCodePurchaseArgs, 'input'>>;
  ActivationCodeUse?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationActivationCodeUseArgs, 'input'>>;
  CacheClear?: Resolver<Maybe<ResolversTypes['ApiResponse']>, ParentType, ContextType, RequireFields<MutationCacheClearArgs, 'queryName'>>;
  ExperienceUpdateInfo?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationExperienceUpdateInfoArgs, 'input' | 'onboarding_code'>>;
  GroupDelete?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType>;
  LandingContestSaveUser?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationLandingContestSaveUserArgs, 'input'>>;
  NewsletterUnsubscribeMember?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationNewsletterUnsubscribeMemberArgs, 'input'>>;
  NewsletterAddVisitor?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationNewsletterAddVisitorArgs, 'email'>>;
  OtpGet?: Resolver<ResolversTypes['OtpGetResponse'], ParentType, ContextType, RequireFields<MutationOtpGetArgs, 'input'>>;
  OtpVerify?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationOtpVerifyArgs, 'input'>>;
  PartnerUserLogout?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType>;
  PartnerLogin?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnerLoginArgs, 'input'>>;
  PartnerRemindCredentials?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnerRemindCredentialsArgs, 'input'>>;
  PartnerRemindAdminPassword?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnerRemindAdminPasswordArgs, 'input'>>;
  PartnerCreateAdminPassword?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnerCreateAdminPasswordArgs, 'input'>>;
  PartnerChangeAdminPassword?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnerChangeAdminPasswordArgs, 'input'>>;
  PartnerAuthAdmin?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnerAuthAdminArgs, 'input'>>;
  PartnershipAcceptTerms?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnershipAcceptTermsArgs, 'onboarding_code' | 'accept_terms_partner_user_id' | 'partner_type'>>;
  PartnershipOnboardingCodeLogin?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnershipOnboardingCodeLoginArgs, 'onboarding_code'>>;
  PartnerUserLoginOrCreate?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPartnerUserLoginOrCreateArgs, 'input'>>;
  PaymentMethodAdd?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPaymentMethodAddArgs, 'input'>>;
  PaymentMethodRemove?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationPaymentMethodRemoveArgs, 'input'>>;
  RegisterToPromotionalPartner?: Resolver<Maybe<ResolversTypes['ApiResponse']>, ParentType, ContextType, RequireFields<MutationRegisterToPromotionalPartnerArgs, 'promotional_partner_id' | 'user_id_at_partner'>>;
  UnregisterFromPromotionalPartner?: Resolver<Maybe<ResolversTypes['ApiResponse']>, ParentType, ContextType, RequireFields<MutationUnregisterFromPromotionalPartnerArgs, 'promotional_partner_id'>>;
  RedeemCodeUse?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationRedeemCodeUseArgs, 'code'>>;
  ProductPurchase?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationProductPurchaseArgs, 'input'>>;
  SubscriptionUpdate?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationSubscriptionUpdateArgs, 'input'>>;
  SubscriptionPurchase?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationSubscriptionPurchaseArgs, 'input'>>;
  UserActivateLegacySubscription?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType>;
  UserDelete?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType>;
  UserLogin?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUserLoginArgs, 'input'>>;
  UserLogout?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType>;
  UserOtpGet?: Resolver<ResolversTypes['UserOtpGetResponse'], ParentType, ContextType, RequireFields<MutationUserOtpGetArgs, 'input'>>;
  UserOtpLogin?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationUserOtpLoginArgs, 'input'>>;
  UserUpdate?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationUserUpdateArgs, 'input'>>;
  VisitConfirm?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationVisitConfirmArgs, 'input'>>;
  VisitConfirmMember?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationVisitConfirmMemberArgs, 'input'>>;
  VisitUpdate?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType, RequireFields<MutationVisitUpdateArgs, 'input'>>;
  FreeVisitCreate?: Resolver<ResolversTypes['ApiResponse'], ParentType, ContextType>;
  WishlistUpdateExperiences?: Resolver<Maybe<ResolversTypes['ApiResponse']>, ParentType, ContextType, RequireFields<MutationWishlistUpdateExperiencesArgs, 'input'>>;
  WishlistCreate?: Resolver<Maybe<ResolversTypes['ApiResponse']>, ParentType, ContextType, RequireFields<MutationWishlistCreateArgs, 'input'>>;
  WishlistDelete?: Resolver<Maybe<ResolversTypes['ApiResponse']>, ParentType, ContextType, RequireFields<MutationWishlistDeleteArgs, 'input'>>;
  WishlistUpdate?: Resolver<Maybe<ResolversTypes['ApiResponse']>, ParentType, ContextType, RequireFields<MutationWishlistUpdateArgs, 'input'>>;
};

export type ActivationCodeResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ActivationCode'] = ResolversParentTypes['ActivationCode']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  base_duration?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extra_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_user_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_user_first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_user_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  purchase_user_last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recipient_user_email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient_user_first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient_user_last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  used_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  used_by_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  valid_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvinceResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Province'] = ResolversParentTypes['Province']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  balance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributorDiscountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['DistributorDiscount'] = ResolversParentTypes['DistributorDiscount']> = {
  applicable_product_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discounted_amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributorResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Distributor'] = ResolversParentTypes['Distributor']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extra_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  header_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logo_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offer_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavingsAmountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['SavingsAmount'] = ResolversParentTypes['SavingsAmount']> = {
  savings_amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['Province']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  postal_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocResolvers<ContextType = Context, ParentType extends ResolversParentTypes['GeoLoc'] = ResolversParentTypes['GeoLoc']> = {
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentContractResolvers<ContextType = Context, ParentType extends ResolversParentTypes['CurrentContract'] = ResolversParentTypes['CurrentContract']> = {
  signed_date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  signed_contact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = {
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpeningHourResolvers<ContextType = Context, ParentType extends ResolversParentTypes['OpeningHour'] = ResolversParentTypes['OpeningHour']> = {
  day?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  open?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionAnswerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['QuestionAnswer'] = ResolversParentTypes['QuestionAnswer']> = {
  question?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Owner'] = ResolversParentTypes['Owner']> = {
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExperienceResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Experience'] = ResolversParentTypes['Experience']> = {
  _geoloc?: Resolver<Maybe<ResolversTypes['GeoLoc']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  additional_images?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  categories_names?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  company_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company_phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  current_contract_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_contract?: Resolver<Maybe<ResolversTypes['CurrentContract']>, ParentType, ContextType>;
  current_user_rating?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_acquisition_cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customers_distance_close?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customers_distance_far?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customers_distance_medium?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date_created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date_published?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date_updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discovered_via_milo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  google_maps_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gross_sales?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['Image']>>>, ParentType, ContextType>;
  in_wishlist?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_legacy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  main_image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opening_hours?: Resolver<Maybe<Array<Maybe<ResolversTypes['OpeningHour']>>>, ParentType, ContextType>;
  owners?: Resolver<Maybe<Array<Maybe<ResolversTypes['Owner']>>>, ParentType, ContextType>;
  partnerships?: Resolver<Maybe<Array<Maybe<ResolversTypes['Partnership']>>>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  questions_answers?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuestionAnswer']>>>, ParentType, ContextType>;
  ratings?: Resolver<Maybe<ResolversTypes['Ratings']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Region']>, ParentType, ContextType>;
  reservation_conditions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  restrictions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promo_code_instructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roi?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  savings_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  savings_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  savings_matrix?: Resolver<Maybe<ResolversTypes['SavingsMatrix']>, ParentType, ContextType>;
  savings_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  savings_percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  savings_type?: Resolver<Maybe<ResolversTypes['SavingsTypes']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ExperienceStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  terms_accepted_by?: Resolver<Maybe<ResolversTypes['PartnerUser']>, ParentType, ContextType>;
  terms_accepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  thumbnail_image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  two_for_one_max_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vertical_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  verticals_ephemeral_ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  visited_this_year?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  visits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wishlist_revenue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wishlist_total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavingsMatrixResolvers<ContextType = Context, ParentType extends ResolversParentTypes['SavingsMatrix'] = ResolversParentTypes['SavingsMatrix']> = {
  amount1?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  amount2?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  amount3?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  savings1?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  savings2?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  savings3?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RatingsResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Ratings'] = ResolversParentTypes['Ratings']> = {
  bad?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  good?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  great?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  experiences?: Resolver<Maybe<Array<Maybe<ResolversTypes['Experience']>>>, ParentType, ContextType>;
  admin_contacts?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GuideResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Guide'] = ResolversParentTypes['Guide']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  city_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  experiences?: Resolver<Maybe<Array<Maybe<ResolversTypes['Experience']>>>, ParentType, ContextType>;
  long_description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  main_image_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meta_description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Region']>, ParentType, ContextType>;
  related_guides?: Resolver<Maybe<Array<Maybe<ResolversTypes['Guide']>>>, ParentType, ContextType>;
  savings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tag']>>>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vertical?: Resolver<Maybe<ResolversTypes['Vertical']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent_entity_type?: Resolver<ResolversTypes['ParentEntityType'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offered_as_gift?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MailingListResolvers<ContextType = Context, ParentType extends ResolversParentTypes['MailingList'] = ResolversParentTypes['MailingList']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OtpGetResponseResolvers<ContextType = Context, ParentType extends ResolversParentTypes['OtpGetResponse'] = ResolversParentTypes['OtpGetResponse']> = {
  secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Partner'] = ResolversParentTypes['Partner']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  experiences?: Resolver<Maybe<Array<Maybe<ResolversTypes['Experience']>>>, ParentType, ContextType>;
  is_admin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cell_phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_login_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType>;
  has_admin_password?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerStatsConversionsResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnerStatsConversions'] = ResolversParentTypes['PartnerStatsConversions']> = {
  revenue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partnerValidatedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  memberValidatedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerStatsGenderDistributionResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnerStatsGenderDistribution'] = ResolversParentTypes['PartnerStatsGenderDistribution']> = {
  m?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  f?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  o?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerStatsAgeDistributionResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnerStatsAgeDistribution'] = ResolversParentTypes['PartnerStatsAgeDistribution']> = {
  _18_25?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _26_35?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _36_49?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _50_plus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerStatsRegionDistributionResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnerStatsRegionDistribution'] = ResolversParentTypes['PartnerStatsRegionDistribution']> = {
  local?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  other?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerMonthlyVisitsResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnerMonthlyVisits'] = ResolversParentTypes['PartnerMonthlyVisits']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerStatsResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnerStats'] = ResolversParentTypes['PartnerStats']> = {
  age_distribution?: Resolver<ResolversTypes['PartnerStatsAgeDistribution'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['PartnerStatsConversions'], ParentType, ContextType>;
  gender_distribution?: Resolver<ResolversTypes['PartnerStatsGenderDistribution'], ParentType, ContextType>;
  monthly_visits?: Resolver<Array<Maybe<ResolversTypes['PartnerMonthlyVisits']>>, ParentType, ContextType>;
  region_distribution?: Resolver<ResolversTypes['PartnerStatsRegionDistribution'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactEmailResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ContactEmail'] = ResolversParentTypes['ContactEmail']> = {
  emails?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnershipResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Partnership'] = ResolversParentTypes['Partnership']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  is_current?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  auto_renew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  experience_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partner_user_accepted?: Resolver<Maybe<ResolversTypes['PartnerUser']>, ParentType, ContextType>;
  accept_terms_date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  passed_onboarding_date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  onboarding_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['PartnershipContract']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnershipContractResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnershipContract'] = ResolversParentTypes['PartnershipContract']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_created?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user_updated?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  date_created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date_updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  version_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerUserResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartnerUser'] = ResolversParentTypes['PartnerUser']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  experiences?: Resolver<Maybe<Array<Maybe<ResolversTypes['Experience']>>>, ParentType, ContextType>;
  is_admin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cell_phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_login_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiry_year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PointOfInterestResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PointOfInterest'] = ResolversParentTypes['PointOfInterest']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partner?: Resolver<Maybe<ResolversTypes['PointOfInterestPartner']>, ParentType, ContextType>;
  primary_tag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  secondary_tags?: Resolver<Array<Maybe<ResolversTypes['Tag']>>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PointOfInterestPartnerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PointOfInterestPartner'] = ResolversParentTypes['PointOfInterestPartner']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logo_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionalPartnerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PromotionalPartner'] = ResolversParentTypes['PromotionalPartner']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price_cents_cad?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  base_price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interval_type?: Resolver<ResolversTypes['ProductInterval'], ParentType, ContextType>;
  interval_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  is_recurring?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  special_price_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoCodeResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PromoCode'] = ResolversParentTypes['PromoCode']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applicable_product_id?: Resolver<ResolversTypes['ProductId'], ParentType, ContextType>;
  amount_off?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_off?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  usage_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  max_usage_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  extra_days?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  valid_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expires_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Promotion'] = ResolversParentTypes['Promotion']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['PromotionDiscount']>>>, ParentType, ContextType>;
  starts_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ends_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionDiscountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PromotionDiscount'] = ResolversParentTypes['PromotionDiscount']> = {
  promo_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applicable_product_id?: Resolver<ResolversTypes['ProductId'], ParentType, ContextType>;
  discounted_amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CountryCodeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CountryCode'], any> {
  name: 'CountryCode';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type SubscriptionResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  id?: SubscriptionResolver<ResolversTypes['ID'], "id", ParentType, ContextType>;
  name?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "name", ParentType, ContextType>;
  description?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "description", ParentType, ContextType>;
  type?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionType']>, "type", ParentType, ContextType>;
  product_type?: SubscriptionResolver<Maybe<ResolversTypes['ProductType']>, "product_type", ParentType, ContextType>;
  stripe_product_id?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "stripe_product_id", ParentType, ContextType>;
  product_id?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "product_id", ParentType, ContextType>;
  status?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionStatus']>, "status", ParentType, ContextType>;
  auto_renew?: SubscriptionResolver<Maybe<ResolversTypes['Boolean']>, "auto_renew", ParentType, ContextType>;
  started_at?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "started_at", ParentType, ContextType>;
  pending_activation_date?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "pending_activation_date", ParentType, ContextType>;
  cancel_date?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "cancel_date", ParentType, ContextType>;
  current_period_start?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "current_period_start", ParentType, ContextType>;
  current_period_end?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "current_period_end", ParentType, ContextType>;
  price_cents?: SubscriptionResolver<ResolversTypes['Int'], "price_cents", ParentType, ContextType>;
  interval_count?: SubscriptionResolver<ResolversTypes['Int'], "interval_count", ParentType, ContextType>;
  interval?: SubscriptionResolver<ResolversTypes['ProductInterval'], "interval", ParentType, ContextType>;
};

export type TagResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date_created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date_updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionalPartnerMembershipInfoResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PromotionalPartnerMembershipInfo'] = ResolversParentTypes['PromotionalPartnerMembershipInfo']> = {
  partner_id?: Resolver<ResolversTypes['PromotionalPartnerIds'], ParentType, ContextType>;
  membership_number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  membership_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membership_status?: Resolver<ResolversTypes['PromotionalPartnerStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionScheduleResolvers<ContextType = Context, ParentType extends ResolversParentTypes['SubscriptionSchedule'] = ResolversParentTypes['SubscriptionSchedule']> = {
  priceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = Context, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  promotional_partners_memberships?: Resolver<Array<Maybe<ResolversTypes['PromotionalPartnerMembershipInfo']>>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  birth_date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  facebook_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  free_visits?: Resolver<Array<Maybe<ResolversTypes['FreeVisit']>>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  google_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  has_passed_onboarding?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  has_pending_free_visit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  has_pending_prepaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invoices?: Resolver<Maybe<Array<Maybe<ResolversTypes['Invoice']>>>, ParentType, ContextType>;
  is_legacy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  last_login_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_used_free_visit?: Resolver<Maybe<ResolversTypes['FreeVisit']>, ParentType, ContextType>;
  member_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middle_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment_method?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  pending_free_visit?: Resolver<Maybe<ResolversTypes['FreeVisit']>, ParentType, ContextType>;
  pending_promo_codes?: Resolver<Maybe<Array<Maybe<ResolversTypes['PromoCode']>>>, ParentType, ContextType>;
  privilege_interval?: Resolver<Maybe<ResolversTypes['PrivilegeInterval']>, ParentType, ContextType>;
  privilege_reset_day?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['UserStatus']>, ParentType, ContextType>;
  stripe_customer_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  stripe_subscription_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  subscriptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Subscription']>>>, ParentType, ContextType>;
  scheduled_subscriptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubscriptionSchedule']>>>, ParentType, ContextType>;
  visited_this_year?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  visits_this_year?: Resolver<Maybe<Array<Maybe<ResolversTypes['Visit']>>>, ParentType, ContextType>;
  visits?: Resolver<Maybe<Array<Maybe<ResolversTypes['Visit']>>>, ParentType, ContextType>;
  wishlists?: Resolver<Maybe<Array<Maybe<ResolversTypes['Wishlist']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOnboardingDataResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UserOnboardingData'] = ResolversParentTypes['UserOnboardingData']> = {
  cents_credits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  free_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  has_passed_onboarding?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment_method?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  reactivated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  stripe_customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrivilegeIntervalResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrivilegeInterval'] = ResolversParentTypes['PrivilegeInterval']> = {
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOtpGetResponseResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UserOtpGetResponse'] = ResolversParentTypes['UserOtpGetResponse']> = {
  secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOtpResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UserOtp'] = ResolversParentTypes['UserOtp']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attempts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  used_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expires_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postal_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  route?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerticalResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Vertical'] = ResolversParentTypes['Vertical']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerticalEphemeralResolvers<ContextType = Context, ParentType extends ResolversParentTypes['VerticalEphemeral'] = ResolversParentTypes['VerticalEphemeral']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hex_color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  svg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starts_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ends_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Visit'] = ResolversParentTypes['Visit']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  savings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  experience_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType>;
  reduced_invoice_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  discovered_with_milo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['VisitConfirmType']>, ParentType, ContextType>;
  promo_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitHistoryRowResolvers<ContextType = Context, ParentType extends ResolversParentTypes['VisitHistoryRow'] = ResolversParentTypes['VisitHistoryRow']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date_created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  validated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoice_total_before_tax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FreeVisitResolvers<ContextType = Context, ParentType extends ResolversParentTypes['FreeVisit'] = ResolversParentTypes['FreeVisit']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visit_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visit_date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  valid_until_date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  created_date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated_date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Wishlist'] = ResolversParentTypes['Wishlist']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  experiences?: Resolver<Maybe<Array<Maybe<ResolversTypes['Experience']>>>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistItemResolvers<ContextType = Context, ParentType extends ResolversParentTypes['WishlistItem'] = ResolversParentTypes['WishlistItem']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  experience_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wishlist_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date_updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = Context> = {
  ApiResponse?: ApiResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  ActivationCode?: ActivationCodeResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  Province?: ProvinceResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  DistributorDiscount?: DistributorDiscountResolvers<ContextType>;
  Distributor?: DistributorResolvers<ContextType>;
  SavingsAmount?: SavingsAmountResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  GeoLoc?: GeoLocResolvers<ContextType>;
  CurrentContract?: CurrentContractResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  OpeningHour?: OpeningHourResolvers<ContextType>;
  QuestionAnswer?: QuestionAnswerResolvers<ContextType>;
  Owner?: OwnerResolvers<ContextType>;
  Experience?: ExperienceResolvers<ContextType>;
  SavingsMatrix?: SavingsMatrixResolvers<ContextType>;
  Ratings?: RatingsResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  Guide?: GuideResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Invoice?: InvoiceResolvers<ContextType>;
  MailingList?: MailingListResolvers<ContextType>;
  OtpGetResponse?: OtpGetResponseResolvers<ContextType>;
  Partner?: PartnerResolvers<ContextType>;
  PartnerStatsConversions?: PartnerStatsConversionsResolvers<ContextType>;
  PartnerStatsGenderDistribution?: PartnerStatsGenderDistributionResolvers<ContextType>;
  PartnerStatsAgeDistribution?: PartnerStatsAgeDistributionResolvers<ContextType>;
  PartnerStatsRegionDistribution?: PartnerStatsRegionDistributionResolvers<ContextType>;
  PartnerMonthlyVisits?: PartnerMonthlyVisitsResolvers<ContextType>;
  PartnerStats?: PartnerStatsResolvers<ContextType>;
  ContactEmail?: ContactEmailResolvers<ContextType>;
  Partnership?: PartnershipResolvers<ContextType>;
  PartnershipContract?: PartnershipContractResolvers<ContextType>;
  PartnerUser?: PartnerUserResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  PointOfInterest?: PointOfInterestResolvers<ContextType>;
  PointOfInterestPartner?: PointOfInterestPartnerResolvers<ContextType>;
  PromotionalPartner?: PromotionalPartnerResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  PromoCode?: PromoCodeResolvers<ContextType>;
  Promotion?: PromotionResolvers<ContextType>;
  PromotionDiscount?: PromotionDiscountResolvers<ContextType>;
  Region?: RegionResolvers<ContextType>;
  CountryCode?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  Void?: GraphQLScalarType;
  Subscription?: SubscriptionResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  PromotionalPartnerMembershipInfo?: PromotionalPartnerMembershipInfoResolvers<ContextType>;
  SubscriptionSchedule?: SubscriptionScheduleResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserOnboardingData?: UserOnboardingDataResolvers<ContextType>;
  PrivilegeInterval?: PrivilegeIntervalResolvers<ContextType>;
  UserOtpGetResponse?: UserOtpGetResponseResolvers<ContextType>;
  UserOtp?: UserOtpResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  Vertical?: VerticalResolvers<ContextType>;
  VerticalEphemeral?: VerticalEphemeralResolvers<ContextType>;
  Visit?: VisitResolvers<ContextType>;
  VisitHistoryRow?: VisitHistoryRowResolvers<ContextType>;
  FreeVisit?: FreeVisitResolvers<ContextType>;
  Wishlist?: WishlistResolvers<ContextType>;
  WishlistItem?: WishlistItemResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = Context> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = Context> = {
  adminrequest?: AdminrequestDirectiveResolver<any, any, ContextType>;
  auth?: AuthDirectiveResolver<any, any, ContextType>;
  cache?: CacheDirectiveResolver<any, any, ContextType>;
  partnerauth?: PartnerauthDirectiveResolver<any, any, ContextType>;
  anyauth?: AnyauthDirectiveResolver<any, any, ContextType>;
  partneradmin?: PartneradminDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = Context> = DirectiveResolvers<ContextType>;